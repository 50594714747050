import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import HoverVideoPlayer from 'react-hover-video-player';
import {Link} from "react-router-dom";

//export default ({ image_url, video_url, detection_ts, length, species }) =>

class BirdDetection extends Component {

  constructor(props) {
  	super(props);
        this.state = {
            hover: false,
            border: "",
            mobile: false
  	};
  }
  
  mouseenter = () => {
  	this.setState({hover: true, border: 'primary'});
  }
  mouseleave = () => {
  	this.setState({hover: false, border: ''});
  }
  
  isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  } 

  componentDidMount() {
      
  	this.setState({mobile: this.isMobileDevice()});
  }


  render() {
      var jpg_url = this.props.image_url.replace(/.webp$/,'.jpg');

      return (
  <div onMouseEnter={this.mouseenter} onMouseLeave={this.mouseleave}>
  <Card border={this.state.border} style={{width:'100%'}} >
    
      <HoverVideoPlayer
        videoSrc={this.props.video_url}
        pausedOverlay={
          <picture>
              <source srcSet={this.props.image_url} type="image/webp" />
              <img src={jpg_url} className="card-img-top" alt="Image of bird" type="image/jpg" />
          </picture>
        }
        className="card-img-top"	
        pausedOverlayWrapperClassName="card-img-top"
        preload="none"
        sizingMode="overlay"
    />
    <Card.Body style={{padding: '0rem', fontSize: '0.8rem'}}>
      <Link to={"/history/"+this.props.camera+"/species/"+this.props.species}><Badge variant="secondary">{this.props.species}</Badge></Link><br />
      <a href={this.props.video_url}>{this.props.detection_ts}</a> - {this.props.length.toFixed(1)}s
    </Card.Body>
  </Card> </div>);
  }

}

export default BirdDetection;
