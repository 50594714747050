import React, { Component } from 'react'
import { LinkContainer } from "react-router-bootstrap";
import {Nav, Navbar, Dropdown} from 'react-bootstrap';
import {CAMERAS, DEFAULTCAM} from './constants.js';

class NavBird extends Component {
  render() {
    let curcam = this.props.camera !== undefined ? this.props.camera : DEFAULTCAM;

    let camselector = Object.values(CAMERAS.map((camname, index) => 
        <Dropdown.Item href={"/history/" + camname} active={camname == curcam}>{camname}</Dropdown.Item>
        ,)
    )

    return (
       <div>
         <Navbar sticky="top" collapseOnSelect bg="light" expand="lg" style={{paddingTop: '4px', paddingBottom: '4px'}}>
           <Navbar.Brand style={{fontSize: '1rem', paddingTop: '0px', paddingBottom: '0px'}}>
               <Dropdown>
                 <Dropdown.Toggle variant="info" id="dropdown-basic">
                   {curcam}
                 </Dropdown.Toggle>
               
                 <Dropdown.Menu>
                     {camselector}
                 </Dropdown.Menu>
               </Dropdown>
           </Navbar.Brand>
           <Navbar.Toggle/>
           <Navbar.Collapse>
              <Nav variant="tabs">
                <LinkContainer exact to={"/history/" + curcam}>
                   <Nav.Link>Stream</Nav.Link>
                </LinkContainer>
                <LinkContainer to={"/graphs/" + curcam}>
                   <Nav.Link>Graphs</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                   <Nav.Link>About</Nav.Link>
                </LinkContainer>
              </Nav>
           </Navbar.Collapse>
         </Navbar>
       </div>
    );
  }
}

export default NavBird
