import React, { Component } from 'react'
import { LinkContainer } from "react-router-bootstrap";
import {Nav, Navbar, Dropdown} from 'react-bootstrap';
import Routes from "./Routes";
import {CAMERAS} from './constants.js';

class Birds extends Component {
  render() {


    return (
         <Routes />
    );
  }
}

export default Birds
