import React, { Component }  from "react";
import {FlexibleWidthXYPlot, VerticalBarSeries, LineSeries, LabelSeries, VerticalGridLines, HorizontalGridLines, YAxis, XAxis, Hint} from 'react-vis';
import '../node_modules/react-vis/dist/style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

import {API, DEFAULTCAM, ANNOTATIONS, DEFAULTAGG} from './constants.js';
//const API = 'https://birdcam-view-api.cathcart.cx/camera/'
// Query before end of today - which is today + 1 day. AFter 6 months ago
const BEFORE=new Date(new Date().getTime() + 1000*60*60*24).toISOString().split('T')[0]
const AFTER=new Date(new Date().getTime() - 1000*60*60*24*30*6).toISOString().split('T')[0]

class BirdGraphs extends Component {
 
  state = {
    speciesgraphdata: [],
    annotations: [],
    maxval: 0,
    maxtotalval: 0,
    mintimestamp: Number.MAX_SAFE_INTEGER
  };
 
  // Force a 0 offset since we know response is in utc but doesn't contain offset
  componentDidMount() {
    var cameraname = this.props.camera ? this.props.camera : DEFAULTCAM ;
    var after_string = this.props.fullRange === '1' ? '' :  '&after=' +AFTER
    var aggregation = this.props.count ? 'count' : DEFAULTAGG;
    var QUERY = 'before=' + BEFORE + after_string;
    var api_url = API + cameraname + '/speciesaggregate/all?' + QUERY;
    fetch(api_url)
      .then(response => response.json())
      .then(
         (data) => {
                    var localspeciesdata = {};
                    var localallspecies = []
                    var maxval = 0;
                    var maxtotalval = 0;
                    var mintimestamp = Number.MAX_SAFE_INTEGER;
                    data.map( dayitem => {
                        var currentdate = Date.parse(dayitem[0]+"+0000");
                        var sumduration = 0.0;
                        Object.keys(dayitem[1]).map( species => {
                            species in localspeciesdata || (localspeciesdata[species] = []);
                            localspeciesdata[species].push({x: currentdate, y: dayitem[1][species][aggregation]}); 
                            maxval = Math.max(maxval,  dayitem[1][species][aggregation]);
                            sumduration += dayitem[1][species][aggregation];
                        });
                        localallspecies.push({x: currentdate, y: sumduration});
                        maxtotalval = Math.max(maxtotalval,  sumduration);
                        mintimestamp = Math.min(mintimestamp, currentdate);
                    });
                    this.setState({speciesgraphdata: [['All_Birds', localallspecies]].concat(Object.entries(localspeciesdata)), 
                                                       maxval: maxval, 
                                                       maxtotalval: maxtotalval, 
                                                       mintimestamp: mintimestamp})
                    
        }
      );
    fetch(ANNOTATIONS)
      .then(response => response.json())
      .then(
         (data) => {
                    this.setState({annotations: data.map(d => [Date.parse(d[0]), d[1]])})

        } 
       );

  }

  render() {

    return (
      <div className="Home">
          <LinkedBirdChart 
               graphdata={this.state.speciesgraphdata}
               maxval={this.state.maxval}
               maxtotalval={this.state.maxtotalval}
               mintimestamp={this.state.mintimestamp}
               fullRange={this.props.fullRange}
               annotations={this.state.annotations}
               camera={this.props.camera ? this.props.camera : DEFAULTCAM} />
        </div>
    );
  }
}


export default BirdGraphs


class LinkedBirdChart extends Component {
  constructor(props) {
    super(props);
    this.state = {hover_x: null, hover_idx: null, hover_species: null};
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }
  handleMouseOver(x_val, idx, hover_species) {
    if( x_val ) {
        this.setState({hover_x: x_val, hover_idx: idx, hover_species: hover_species});
    }
  }
  render() {
    const hover_x = this.state.hover_x;
    const hover_idx = this.state.hover_idx;
    return (<div><div className="sticky-top" style={{height: "56px", top: "56px"}}>
      { this.props.fullRange != '1' ? 
          <Button variant="outline-secondary" href={"/graphs/"+this.props.camera+"?fullRange=1"} >Zoom Out</Button> :
          <Button variant="outline-secondary" href={"/graphs/"+this.props.camera} >Last 6 months</Button> }
      {' '}
      { this.state.hover_species ?
          <Button variant="outline-secondary" href={getStreamHistoryUrl(this.state.hover_x, this.state.hover_species, this.props.camera)} active >View {this.state.hover_species} before {humanDate(this.state.hover_x)} </Button> :
          "" }
      {' '}
      </div>
      {this.props.graphdata.map((d, i) => (<div key={i}>
        <BirdChart
          data={d[1]}
          hover_x={hover_x}
          hover_idx={hover_idx}
          handleMouseOver={this.handleMouseOver}
          species={d[0]}
          maxval={this.props.maxval} 
          maxtotalval={this.props.maxtotalval}
          mintimestamp={this.props.mintimestamp}
          annotations={this.props.annotations} 
          camera={this.props.camera} />
        </div>))}
    </div>);
  }
}

function humanDate(ts) {
    return new Date(ts+1000*60*60*24).toLocaleDateString()
}

function getStreamHistoryUrl(ts, species, camera) {
    var apibefore = new Date(ts+1000*60*60*24).toISOString().replace(/Z$/, '');
    var api_species = species == 'All_Birds' ? 'all' : species 
    var url = '/history/'+ camera+'/species/'+api_species+'?before='+apibefore;
    return url;

}



function BirdChart({data, hover_x, hover_idx, handleMouseOver, species, maxval, maxtotalval, mintimestamp, annotations, camera}) {
  const history = useHistory();
  const handleClick = () => {
      var url = getStreamHistoryUrl(hover_x, species, camera);
      var ismobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
      if (!ismobile) {
          history.push(url)
      }
  };
  return (
    <Container>
      <Row>
        <Col sm={9}>
          <FlexibleWidthXYPlot
                 xType="time"
                height={species === 'All_Birds' ? 200 : 70}
                margin={{top: 0, right: 0, left: 60, bottom: species === 'All_Birds' ? 45 : 0}}
                onMouseLeave={() => handleMouseOver(null)} 
                yDomain={species === 'All_Birds' ? null : [0, maxval]}
                onMouseDown={() => handleClick() }
          >
            
            {species === 'All_Birds' ?  <HorizontalGridLines /> : null }
            {species === 'All_Birds' ?  <VerticalGridLines /> : null }
            {species === 'All_Birds' ?  <XAxis tickLabelAngle={-30} /> : null }



            
            <VerticalBarSeries
              data={data}
              onNearestX={(datapoint, {index}) => handleMouseOver(datapoint['x'], index, species)}
              />
            {hover_x === null ? null : <LineSeries
              data={[{x: hover_x, 
                      y: 0}, 
                     {x: hover_x, y: species === 'All_Birds' ? maxtotalval : maxval}]}
              opacity={0.5} stroke="#2c51be" />
            }
            {hover_x === null ? null : <LabelSeries
              data={[{x: hover_x, 
                      y: species === 'All_Birds' ? maxtotalval : maxval*0.8, 
                      label: 'Time on feeder: ' + new Date(Math.floor(data[hover_idx].y) * 1000).toISOString().substr(11, 8) + ', Date: ' + new Date(hover_x).toLocaleDateString(), 
                      style: {fontSize: 8}}]}
              />
            }
            <LabelSeries data={[{x: mintimestamp, 
                                 y: species === 'All_Birds' ? maxtotalval : maxval*0.4 ,
                                 label: species, style: {fontSize: 10}}]} 
                         labelAnchorX="start" />
      
            {species === 'All_Birds' ?  <YAxis title="Seconds on feeder" /> : null }

            {species === 'All_Birds' ? annotations.filter(ann => ann[0] >= mintimestamp).map((d, i) => (<LineSeries key={i}
                  data={[{x: d[0], 
                          y: 0}, 
                         {x: d[0], y: maxtotalval*0.5}]}
                  opacity={0.5} stroke="#FF9833" />
                  )): null}
            {species === 'All_Birds' && hover_x != null ? annotations.filter(ann => ann[0] >= mintimestamp && hover_x >= ann[0]-2*86400*1000 &&  hover_x <= ann[0]+2*86400*1000).map((d, i) => (<Hint key={i} value={{x: d[0], y: maxtotalval*0.5}} style={{ color: 'red', fontSize: 10, background: 'black' }} >
                     <div key={i} className="rv-hint__content"> 
                        { d[1] } 
                     </div>
                 </Hint> 
                  )): null}
          </FlexibleWidthXYPlot>
        </Col>
        <Col sm={3}></Col>
      </Row>
    </Container>
    );
}
