import { chunk } from 'lodash'
import * as React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
type GridGeneratorProps = {
  cols: 1 | 2 | 3 | 4 | 6 | 12
}
const GridGenerator: React.FC<GridGeneratorProps> = ({ cols, children }) => {
 
const colWidth = 12 / cols
const rows = chunk(React.Children.toArray(children), cols)
return (
    <Container>
      {rows.map((cols) => (
        <Row>
          {cols.map((col) => (
            <Col sm={12} md={colWidth} style={{ paddingRight: '5px', paddingLeft: '5px', paddingBottom: '15px' }}>
              {col}
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  )
}
export default GridGenerator
