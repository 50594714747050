import React from "react";

export default function About() {
  return (
    <div className="about">
      <h3>About birdcamfd</h3>
      <ul>
        <li> <b>What is it?:</b> Cameras pointed at birds in Dublin, Ireland that record videos of and classifies the birds it sees.</li>  
        <li> <b>How does it work?:</b> A long zoom lens connected to a small computer that runs machine learning detection and classification models in real time - results are uploaded to the cloud</li>
        <li> <b>Why are some of the classifications wrong?:</b> Machine learning models aren't perfect and sometimes produce incorrect classifications. Sometimes the orientation of the bird may make it difficult to classify. New species may appear after the model was trained (specifically this applies to the blackcaps currently)</li> 
        <li> <b>Why does the total time on feeder vary so much: </b> There are a variety of reasons. There were some hardware failures in 2019. We took a holiday in September 2020 and feeder refills weren’t possible. In June/July 2020 we moved from feeding full peanuts to feeding kibbled peanuts - this drastically changed the feeding behaviour.</li>
        <li> <b>Have you had any fledgelings?:</b> In 2020 we had multiple broods of robins only one ever ysed the feeder. We also had some juvenile goldfinces.</li>

      </ul>
      <h4>Cameras</h4>
      <ul>
          <li>Birdcamdf: Camera pointed at a feeder, running since april 2019</li>
          <li>nboxone: nextbox with bluetits - since april 2021 - about 5m from birdcamdf</li>
      </ul>
      <h4>Help</h4>
      There are two views:
      <ul>
        <li> <b>Stream View</b>
           <ul>
              <li>Leaving this view open updates live as birds land on the feeder</li>
              <li>Keep scrolling to load more results.</li> 
              <li>Hover over an image to play the video (or lightly touch on mobile).</li> 
              <li>Click on the species name to view just results classified as that species.</li>
              <li>Click on the timestamp to link to the video.</li>
           </ul>
        </li>  
        <li> <b>Graph View</b>
           <ul>
              <li>This view shows the amount of time spent on the feeder per day by all birds and broken down by species.</li> 
              <li>The default view shows the last 6 months - press the zoom out button to view the entire history</li>
              <li>Hover over (or touch on mobile) the graphs to show exact counts/dates</li>
              <li>When not using a touch device you can click on a point in the graph to go to the stream view of that species at that time</li>
              <li>On mobile touch the graph, then click on the "view species before foo" button that appears to jump to the stream at that time</li>
              <li>Orange vertical lines have annotations, hover over them to read</li>
           </ul>
        </li>

      </ul>
    </div>
  );
}
