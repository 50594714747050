import React from "react";
import { Route, Switch, useLocation, Redirect  } from "react-router-dom";
import NavBird from "./NavBird";
import BirdStream from "./BirdStream";
import BirdGraphs from "./BirdGraphs";
import NotFound from "./NotFound";
import About from "./About";

import {DEFAULTCAM} from './constants.js';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Routes() {
  let query = useQuery();

  return (
    <Switch>
      <Route exact path="/history/:camera" children={( {match, location} ) => (
          <div>
              <NavBird camera={match.params.camera} />
              <BirdStream camera={match.params.camera} before={ query.get('before')} />

          </div>
      )} /> 
      <Route exact path="/history/species/:speciesname" children={( {match, location} ) => (
          <Redirect to={"/history/"+DEFAULTCAM+'/species/'+ match.params.speciesname} />
      )} /> 
      <Route path="/history/:camera/species/:speciesname" children={( {match, location} ) => (
          <div>
              <NavBird camera={match.params.camera}/>
              <h3>{match.params.camera} - {match.params.speciesname}</h3>
              <BirdStream camera={match.params.camera} speciesname={match.params.speciesname} before={ query.get('before')} />
          </div>
      )} /> 
      <Route exact path="/graphs">
        <Redirect to={"/graphs/"+DEFAULTCAM} />
      </Route>
      <Route exact path="/graphs/:camera" children={( {match, location} ) => (
        <div>
            <NavBird camera={match.params.camera}/>
            <BirdGraphs camera={match.params.camera} fullRange={query.get("fullRange")} count={query.get("count")} />
        </div>
      )} />
      <Route exact path="/about">
        <div>
            <NavBird />
            <About />
        </div>
      </Route>
      <Route exact path="/">
        <Redirect to={"/history/"+DEFAULTCAM} />
      </Route>
      <Route>
        <div>
            <NavBird />
            <NotFound />
        </div>
      </Route>
    </Switch>
  );
}
