import React, { Component } from 'react'
import ReconnectingWebSocket from 'reconnecting-websocket';
import BirdDetection from './BirdDetection';
import GridGenerator from './GridGenerator';
import { useParams } from "react-router-dom";

import {WEBSOCKETURL, API, DEFAULTCAM} from './constants.js';

class BirdStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      species: this.props.speciesname ? this.props.speciesname : 'all',
      camera: this.props.camera ? this.props.camera : DEFAULTCAM,
      detections: [],
    };

    // infinite scroll
    this.endpageref = React.createRef();
    var observer_options = {
         root: null,
         rootMargin: "20px",
         threshold: 1.0
        };
    const handleEndPageObserver = (entities) => {
          const target = entities[0];
          if (target.isIntersecting && this.state.detections.length > 0) {   
              console.log('load more')    
              this.infinite_fetchData();
          }
    };
    this.endobserver = new IntersectionObserver(handleEndPageObserver, observer_options)
  }

  options = {
      connectionTimeout: 8000,
      debug: true,
      maxReconnectionDelay: 60000,
  };

  initial_messages = false;
 
  infinite_fetchData = () => {
      var qs = '';
      if (this.state.detections.length > 0) {
          qs = '?before=' + this.state.detections[this.state.detections.length -1].detection_ts_iso;
      } else if (this.props.before) {
          qs = '?before=' + this.props.before;
      }
      
      var apiquery = API+this.state.camera+'/species/'+this.state.species+qs
      console.log(apiquery);
      fetch(apiquery)
        .then(response => response.json())
        .then(
             (data) => {
                       this.setState(state => ({ detections: [...state.detections, ...data] }))
                  
                       }
             )

  }
 
  setupWebSocket = () => {
     this.ws = new ReconnectingWebSocket(WEBSOCKETURL, [], this.options)
     this.ws.onopen = () => {
       // on connecting, do nothing but log it to the console
       console.log('connected - requesting history: ' + this.initial_messages)
       this.ws.send(JSON.stringify({"action":"register_watch", "camera": this.state.camera ,"species": this.state.species }))
       if(!this.initial_messages){
         this.ws.send(JSON.stringify({"action":"send_video", "camera": this.state.camera ,"species": this.state.species }))
         this.initial_messages = true
       }
     }

    this.ws.onmessage = evt => {
      // on receiving a message, add it to the list of messages
      console.log(evt)
      if (evt.data) {
          const message = JSON.parse(evt.data)
          console.log(message)
          if ('events' in message) {
              this.addMessage(message)
          }
      }
    }

  }

  componentDidMount() {
    this.endobserver.observe(this.endpageref.current);
    if (!('before' in this.props) || !this.props.before) {
      // live methods can't handle species filtering so far
      console.log('setup websocket');
      this.setupWebSocket();
    } else {
      // fetch initial stream from no websocket api
      this.infinite_fetchData();
    }
  }

  addMessage = message =>
    this.setState(state => ({ detections: [...message['events'], ...state.detections] }))



  render() {
    let birds;
    if ( this.state.detections ) {
        birds = Object.values(this.state.detections.map((detection, index) =>
         <BirdDetection
            key={index}
            image_url={detection.image_url}
            video_url={detection.video_url}
            detection_ts={detection.detection_ts}
            length={detection.length}
            species={detection.species}
            camera={detection.camera}
          />,)
        )
    }	

    return (
       <div>
        <GridGenerator cols={6}>
         {birds}
        </GridGenerator>
        <div className="loading" ref={this.endpageref}>
        </div>
       </div>
    );
  }
}

export default BirdStream

